<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Pengiriman</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Pengiriman</h2>
							<img src="@/assets/images/pengiriman.jpg" alt="pengiriman.jpg" class="w-75 round shadow">
						</div>
						<div class="text-left">
							<p>Untuk  pengiriman  pesanan Anda akan tiba di alamat yang telah Anda tentukan dalam waktu 2-3 hari kerja untuk wilayah Jabodetabek dan 3-7 hari kerja untuk wilayah luar Jabodetabek. Layanan pengiriman sudah mencakup seluruh wilayah Indonesia.</p>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>